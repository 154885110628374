<template>
  <vx-card style="padding-left: 10px">
    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="createQuestionnaireHandler"
          icon-pack="feather"
          icon="icon-edit"
        >Create Question</vs-button>
      </div>
    </div>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div style="float: left">
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="question">Questions</vs-th>
          <vs-th sort-key="isEnabled">Enabled status</vs-th>
          <vs-th sort-key="isEnabled">Mandatory</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].question">{{ data[indextr].question }}</vs-td>
            <vs-td :data="data[indextr].isEnabled">{{ data[indextr].isEnabled ? 'Yes' : 'No' }}</vs-td>
            <vs-td :data="data[indextr].is_mandatory">{{ data[indextr].is_mandatory ? 'Yes' : 'No' }}</vs-td>
            <vs-td
              :data="data[indextr].createdAt"
            >{{ moment(data[indextr].createdAt).format('DD/MM/YYYY') }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vx-tooltip text="View/Edit Details">
                <vs-button
                  style="float: left;"
                  type="border"
                  size="small"
                  @click="editDetailHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-edit"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
              <!-- <vx-tooltip text="Delete">
                <vs-button
                  style="float: left;"
                  type="border"
                  size="small"
                  @click="deleteQuestionnaireHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-delete"
                  class="m-1"
                ></vs-button>
              </vx-tooltip> -->
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
          ? dataTableParams.page * dataTableParams.limit
          : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect
  },
  props: {
    createRoute: {
      type: String,
      default: "QuestionnaireCreate"
    }
  },
  data() {
    return {
      noDataText: "Loading...",
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "asc",
        page: 1,
        limit: 25
      },
      users: [],
      serverResponded: false,
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      awaitingSearch: null
    };
  },
  methods: {
    ...mapActions("questionnaire", [
      "fetchQuestionnaire",
      "deleteQuestionnaire"
    ]),
    moment(date) {
      return moment(date);
    },
    getQuestionnaireList() {
      let self = this;
      this.fetchQuestionnaire(self.dataTableParams).then(res => {
        console.log("Hello");
        console.log(res);
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
        self.serverResponded = true;
        self.noDataText = "No Data Found";
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getQuestionnaireList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getQuestionnaireList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getQuestionnaireList();
    },
    editDetailHandler(id) {
      this.$router.push({
        name: "CMSConsentQuestionnaire",
        params: { id: id }
      });
    },
    createQuestionnaireHandler() {
      this.$router.push({ name: this.createRoute });
    },
    async deleteQuestionnaireHandler(id) {
      let self = this;
      await this.deleteQuestionnaire(id)
        .then(res => {
          self.$vs.notify({
            title: "Question Deleted",
            text: "Question deleted Successfully.",
            color: "success"
          });
          this.getQuestionnaireList();
        })
        .catch(err => {
          self.$vs.notify({
            title: "Failed",
            text: "Failed to delete questionnaire. Try again!",
            color: "danger"
          });
          if (err.status === 422) {
            self.mapServerErrors(self, err, "basic");
          }
        });
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getQuestionnaireList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getQuestionnaireList();
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getQuestionnaireList();
  }
};
</script>
